
import { URL, axios } from '@/util/request.js';

export function getMatterListTotal(data) {
    return axios.post(URL.API_GET_MATTERLISTTOTAL, data);
}

export function projectCreate(data) {
    return axios.post(URL.API_PROJECT_CREATE, data);
}
export function projectUpdate(data) {
    return axios.post(URL.API_PROJECT_UPDATE, data);
}
export function projectGetList(url, data) {
    return axios.post(URL.API_PROJECT_GETLIST + '/' + url, data);
}
export function projectGetInfo(id) {
    return axios.get(URL.API_PROJECT_GETPROJECTINFO + '/' + id);
}

export function projectShareHolder(id) {
    return axios.get(URL.API_PROJECT_SHAREHOLDER + '/' + id);
}
export function projectGetrecdept(id) {
    return axios.get(URL.API_PROJECT_GETRECDEPT + '/' + id);
}
export function projectGetListTotal(data) {
    return axios.post(URL.API_PROJECT_GETLISTTOTAL, data);
}
export function getAttachmentList(id) {
    return axios.get(URL.API_ATTACHMENT_GETLIST + '/' + id);
}
export function getAttachment(data) {
    return axios.post(URL.API_ATTACHMENT_GETDOCTYPEID, data);
}
export function itemCreate(data) {
    return axios.post(URL.API_ITEM_CREATE, data);
}
export function itemUpdate(data) {
    return axios.post(URL.API_ITEM_UPDATE, data);
}
export function getRecord(id) {
    return axios.get(URL.API_GET_RECRECORD + '/' + id);
}

export function approve(id) {
    return axios.post(URL.API_PROJECT_APPROVE + '/' + id);
}

export function createVisit(data) {
    return axios.post(URL.API_PROJECT_CREATE_VISIT, data);
}

export function getVisit(id){
    return axios.get(URL.API_PROJECT_GETVISIT + '/' + id);
}

export function createSiteSelect(data) {
    return axios.post(URL.API_PROJECT_CREATE_ADDRESS, data);
}
// API_PROJECT_ADDRESS
export function getSiteSelect(id){
    return axios.get(URL.API_PROJECT_ADDRESS + '/' + id);
}
