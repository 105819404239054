import { createSiteSelect, getSiteSelect, getAttachmentList } from '@/api/crm/workbench';
export default {
    state() {
        return {
            labels: [
                {
                    name: '选址图件'
                }
            ],
            originData: {
                "landType": '',
                "area": "",
                "lstChooseAddr": [
                    {
                        "projId": 1,
                        "addrName": "",
                        "location": "",
                        "time": "",
                        "area": "",
                        "lstAttachment": []
                    }
                ]
            },
            detailData: null
        }
    },
    actions: {
        submit({ state, getters }) {
            return new Promise((resolve) => {
                state.detailData.lstChooseAddr.forEach(item => {
                    item.projId = getters.getId
                })
                createSiteSelect(state.detailData).then(res => {
                    if (res.status == 200) {
                        resolve()
                    }
                });
            })
        },
        getSiteSelect({ commit, getters, dispatch }) {
            getSiteSelect(getters.getId).then(res => {
                if (res.status == 200) {
                    res.data.lstChooseAddr.forEach(async val => {
                        val.lstAttachment = [];
                        val.lstAttachment = await dispatch('getAttachmentList', val.id);
                    })
                    commit('SET_DETAIL_DATA', res.data);
                }
            })
        },
        getAttachmentList(contnt, id) {
            return getAttachmentList("4/" + id).then(res => {
                if (res.status == 200) {
                    return res.data
                } else {
                    return [];
                }
            }).catch(() => {
                return []
            });
        },
    },
    mutations: {
        SET_DETAIL_DATA(state, data) {
            state.detailData = data;
        },
        RESTE_DATA(state) {
            state.detailData = JSON.parse(JSON.stringify(state.originData));
        },
        ADD_ADDRESS(state) {
            state.detailData.lstChooseAddr.push({
                "addrName": "",
                "location": "",
                "time": "",
                "area": "",
                "lstAttachment": []
            })
        },
        REMOVE_ADDRESS(state, index) {
            state.detailData.lstChooseAddr.splice(index, 1);
        }
    },
    getters: {
        getId(state, getters, rootState, rootGetter) {
            return rootGetter.getById
        }
    }
}