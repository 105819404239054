import {
    projectGetInfo,
    projectShareHolder,
    projectGetrecdept,
    getAttachmentList,
    projectUpdate,
    projectCreate
} from "@/api/crm/workbench";
import { crmGetCustomList } from "@/api/crm/resource_library";
import store from '@/store'
/* projectInfo,lstEnterprise,lstCutomer,lstAttachment */
const register = {
    state() {
        return {
            id: null,
            detailData: {
                'projectInfo': {
                    lstRecDept: null
                },
                'lstEnterprise': null,
                'lstCutomer': null,
                'lstAttachment': null
            },
            originData: {
                lstEnterprise: [
                    {
                        shortName: "", //简称
                        entName: "", //全称
                        industry: "", //产业
                        industryDesc: "", //产业
                        serviceArea: "", //服务领域
                        lstShareHolder: [
                            {
                                sourceArea: "", //来自地区
                                holderName: "", //市外股东
                                investRatio: "", //股东占比
                            },
                        ],
                    },
                ],
                projectInfo: {
                    projName: "", //项目名称
                    status: "1", //项目状态
                    intro: "", //主要建设内容
                    remark: "", //备注
                    contractTime: "", //接触时间
                    priority: "", //优先级
                    fzr: "", //我方负责人
                    yjr: "", //经办人
                    investAmount: "", //投资金额
                    channelSource: "", //线索来源
                    channelSourceDesc: "", //线索渠道来源内容
                    industry: "", //产业类别
                    industryDesc: "", //产业类别其他
                    lstRecDept: [
                        //引荐部
                        {
                            deptName: "",
                            assignRatio: "",
                            lxr: "",
                            lxrPosition: "",
                        },
                    ],
                    yjr: "", //项目引荐人
                    yjrDept: "", //项目引荐人部门
                },
                lstCutomer: [
                    {
                        cstName: "",
                        position: "",
                        tel: "",
                    },
                ],
                lstAttachment: [],
            },
        }
    },
    mutations: {
        SET_PROJECT_INFO(state, data) {
            state.detailData.projectInfo = data;
        },
        SET_SHARE_HOLDER(state, data) {
            state.detailData.lstEnterprise = data;
        },
        SET_CUSTOM_LIST(state, data) {
            state.detailData.lstCutomer = data;
        },
        SET_RECDEPT(state, data) {
            state.detailData.projectInfo.lstRecDept = data;
        },
        SET_ATTACHMENT(state, data) {
            state.detailData.lstAttachment = data;
        },
        RESTE_DATA(state) {
            state.detailData = JSON.parse(JSON.stringify(state.originData));
        },
        SET_ID(state, id) {
            state.id = id;
        }
    },
    actions: {
        getProjectInfo({ commit, state, dispatch }) {
            projectGetInfo(state.id).then((res) => {
                if (res.status == 200) {
                    const projectInfo = {
                        ...state.detailData.projectInfo,
                        ...res.data,
                    };
                    commit('RESET_STATUS');
                    const finishStep = res.data.finishStep.split(',');

                    finishStep.forEach(val => {
                        commit('SET_STATUS', {
                            index: val - 1,
                            val: 2
                        })
                    })

                    commit('SET_PROJECT_INFO', projectInfo);
                    dispatch('getShareHolder');
                    dispatch('getCustomList');
                    dispatch('getAttachmentList');
                    dispatch('getProjectRecdept');
                }
            });
        },
        getShareHolder({ commit, state }) {
            projectShareHolder(state.id).then((res) => {
                if (res.status == 200) {
                    const lstEnterprise = [{
                        lstShareHolder: res.data,
                        shortName: state.detailData.projectInfo.shortName, //简称
                        entName: state.detailData.projectInfo.entName, //全称
                        industry: state.detailData.projectInfo.industry, //产业
                        industryDesc: state.detailData.projectInfo.industryDesc, //产业
                        serviceArea: state.detailData.projectInfo.serviceArea, //服务领域
                    }];
                    commit('SET_SHARE_HOLDER', lstEnterprise);
                }
            });
        },
        getProjectRecdept({ commit, state }) {
            projectGetrecdept(state.id).then((res) => {
                if (res.status == 200) {
                    commit('SET_RECDEPT', res.data);
                }
            });
        },
        getAttachmentList({ commit, state }) {
            getAttachmentList("2/" + state.id).then((res) => {
                if (res.status == 200) {
                    commit('SET_ATTACHMENT', res.data);
                }
            });
        },
        getCustomList({ commit, state }) {
            crmGetCustomList("2", state.id).then((res) => {
                if (res.status == 200) {
                    commit('SET_CUSTOM_LIST', res.data)
                }
            });
        },
        async submitFrom({ state }) {
            const reqData = {
                lstEnterprise: state.detailData.lstEnterprise,
                ...state.detailData.projectInfo,
                lstCutomer: state.detailData.lstCutomer,
                lstAttachment: state.detailData.lstAttachment,
            };
            try {
                if (store.getters.getByActive.condition == 3) {
                    await projectUpdate(reqData);
                } else if (store.getters.getByActive.condition == 2) {
                    await projectCreate(reqData);
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    getters: {
        getById(state) {
            return state.id;
        }
    }
}

export default register