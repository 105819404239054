import Axios from 'axios';
import { Message } from 'element-ui';

require('js-base64').Base64;
const axios = Axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000
})

// 请求拦截
axios.interceptors.request.use(
    value => {
        if (process.env.NODE_ENV == 'production') {
            if (localStorage.getItem('token')) {
                value.headers['token'] = Base64.decode(localStorage.getItem('token'));
            }
        }
        return value;
    },
    error => {
        Message({
            message: error,
            type: error
        })
    }
)

// 响应拦截
axios.interceptors.response.use(
    response => {
        if (response.data != null && response.data['message'] != '401') {
            const data = response.data;
            if (data.status == 200) {
                return data;
            } else {
                Message({
                    message: data.msg,
                    type: 'error'
                })
                return Promise.reject()
            }
        } else if (response.data['message'] == '401') {
            localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('auditor');
            this.$router.go(0);
        } else {
            Message({
                message: '未知错误',
                type: 'error'
            })
            return Promise.reject()
        }
    },
    error => {
        Message({
            message: error,
            type: 'error'
        })
        return Promise.reject()
    }
)

const baseApi = process.env.VUE_APP_BASE_API

const URL = {
    baseURL: baseApi,
    static: 'http://static.diworks.cn/',
    // 用户
    API_GETVERIFICATIONCODE: 'user/getVerificationCode',  //获取校验码
    API_VERIFITICATIONCODELOGIN: 'user/verificationCodeLogin',  //验证码登录
    API_CHECKPHONE: 'user/checkPhone', // 验证手机号
    API_REGISTER: 'user/register', //注册
    API_LOGIN: 'user/login', //密码登录
    AP_RESETPASSWORD: 'user/resetPassword',  //重置密码

    // 模块
    API_ALLMODULE: 'module/allModule',  //面板
    API_MODULELIST: 'module/moduleList',  //

    // CRM
    API_CRM_IMPORT: baseApi + "/" + 'resource/import',  //导入
    API_GET_TEMPLAGE: '/resource/getTemplate',  //获取静态模板
    API_GET_ENTERPRISEDETAIL: '/resource/getEntInfoByProvider', //企业基本信息
    API_GET_ENTINFO: '/resource/getEntInfo', //获取企业信息
    API_GET_RESOURCELIST: '/resource/getList',  //获取企业列表
    API_GET_LISTPAGECOUNT: '/resource/getListTotal', //获取企业列表页数
    API_GET_ITEMINFO: "item/getItemInfo",//获取事项详情
    API_GET_CUSTOMLIST: 'customer/getList',//获取客商列表
    API_GET_ITEM_LIST: 'item/getList', //获取事项列表
    API_GET_MATTERLISTTOTAL: 'item/getListTotal', //获取事项列表数据条数
    API_PROJECT_CREATE: 'project/create', //提交项目登记信息
    API_PROJECT_UPDATE: 'project/update', //提交项目登记信息
    API_PROJECT_GETLIST: "project/getList", //获取项目列表
    API_PROJECT_GETPROJECTINFO: 'project/getProjectInfo', //获取项目详情
    API_PROJECT_SHAREHOLDER: "project/getEntShareHolder",//获取企业市外股东列表
    API_PROJECT_GETRECDEPT: "project/getRecDept", //获取项目引荐部室列表
    API_ATTACHMENT_UPLOAD: baseApi + '/' + 'attachment/upload',  //附件上传
    API_PROJECT_GETLISTTOTAL: 'project/getListTotal', //获取项目列表数据条数
    API_ATTACHMENT_GETLIST: 'attachment/getList',  //获取项目附件
    API_ATTACHMENT_GETDOCTYPEID: "attachment/getDocTypeId",  //获取附件ID
    API_ITEM_CREATE: "item/create",  //提交事项信息
    API_ITEM_UPDATE: "item/update",  //提交事项信息
    API_GET_RECRECORD: "item/getRecRecord",//获取接待记录列表
    API_USER_CHECKAPPORVER: 'user/checkApporver',  //校验当前用户是否项目审核员
    API_PROJECT_APPROVE: 'project/approve', //审核项目
    API_PROJECT_CREATE_VISIT: 'project/createVisit',  //提交项目考察信息
    API_PROJECT_CREATE_ADDRESS: 'project/createChooseAddr',  //提交项目选址信息
    API_PROJECT_GETVISIT: 'project/getVisit', //获取项目考察信息
    API_PROJECT_ADDRESS: '/project/getChooseAddr' //获取项目选址信息
};


export {
    URL,
    axios
}