
import { URL, axios } from '@/util/request.js';

export function crmImport(id) {
    return axios.post(URL.API_CRM_IMPORT + "/" + id);
}
export function crmGetTemplate(id) {
    return axios.get(URL.API_GET_TEMPLAGE + "/" + id);
}

export function crmResList(id) {
    return axios.get(URL.API_GET_RESOURCELIST + "/" + id);
}

export function crmListPageCount(id) {
    return axios.get(URL.API_GET_LISTPAGECOUNT + "/" + id);
}

export function crmEntDetail(id) {
    return axios.get(URL.API_GET_ENTINFO + "/" + id);
}

export function crmEntInfoDetail(url, data) {
    return axios.post(URL.API_GET_ENTERPRISEDETAIL + '/' + url, data);
}
export function crmGetItemInfo(id) {
    return axios.get(URL.API_GET_ITEMINFO + "/" + id);
}
export function crmGetCustomList(type, id) {
    return axios.get(URL.API_GET_CUSTOMLIST + '/' + type + '/' + id);
}

export function getMatterList(url, data) {
    return axios.post(URL.API_GET_ITEM_LIST + '/' + url, data);
}