import Vue from "vue";
import Vuex from "vuex";
import menu from './menu/index'
import projectTable from './resource_store/project/table'
import { step, projectRegister, projectVisit, projectSiteSelect, baseInfo } from './resource_store/project/project_info/index'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    menu,
    projectTable,
    step,
    projectRegister,
    visit: {
      namespaced: true,
      ...projectVisit
    },
    'site-select': {
      namespaced: true,
      ...projectSiteSelect
    },
    baseInfo: {
      namespaced: true,
      ...baseInfo
    }
  }
});
