import { createVisit, getVisit, getAttachmentList } from '@/api/crm/workbench';
export default {
    state() {
        return {
            labels: [
                {
                    name: "拜访考察方案",
                    id: 2,
                },
                {
                    name: "现场照片",
                    id: 4,
                },
                {
                    name: "洽谈结果",
                    id: 3,
                },

                {
                    name: "企业资料",
                    id: 5,
                },
            ],
            originData: [{
                "cstName": "",
                "receptionist": "",
                "time": null,
                "reason": "",
                "remark": "",
                "lstAttachment": []
            }],
            detailData: null
        }
    },
    actions: {
        submit({ state, getters }) {
            return new Promise((resolve) => {
                state.detailData.forEach(item => {
                    item.projId = getters.getId
                })
                createVisit(state.detailData).then(res => {
                    if (res.status == 200) {
                        resolve();
                    }
                })
            })

        },
        getVisit({ commit, getters, dispatch }) {
            getVisit(getters.getId).then(res => {
                if (res.status == 200) {
                    res.data.forEach(async val => {
                        val.lstAttachment = [];
                        val.lstAttachment = await dispatch('getAttachmentList', val.id);
                    })
                    commit('SET_DETAIL_DATA', res.data);
                }
            })

        },
        getAttachmentList(contnt, id) {
            return getAttachmentList("3/" + id).then(res => {
                if (res.status == 200) {
                    return res.data
                } else {
                    return [];
                }
            }).catch(() => {
                return []
            });
        },
    },
    mutations: {
        SET_DETAIL_DATA(state, data) {
            state.detailData = data;
        },
        RESTE_DATA(state) {
            state.detailData = JSON.parse(JSON.stringify(state.originData));
        },
    },
    getters: {
        getId(state, getters, rootState, rootGetter) {
            return rootGetter.getById
        }
    }
}