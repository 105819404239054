import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Empty from '@/components/empty'
import { URL } from '@/util/request'
Vue.use(VueRouter)
const routes = [
    /* 首页 */
    {
        name: 'Home',
        path: '/home',
        redirect: '/home/index',
        meta: {
            title: '首页',
            selected: true,
            defaultIcon: 'home_tab/tab_home.png',
            ActiveDefaultIcon: 'home_tab/tab_home_active.png'
        },
        component: () => import('@/App.vue'),
        children: [{
                path: 'index',
                name: 'homeIndex',
                component: Empty
            },
            {
                path: '/login',
                name: 'login',
                redirect: '/login/1',
                meta: {
                    isLogin: true
                },
                component: () => import('../views/login_module/login.vue'),
                children: [{
                    path: '/login/:id',
                    name: 'loginChild',
                    meta: {
                        isLogin: true
                    },
                    component: () => import('../views/login_module/login/login')
                }, ]
            },
            {
                path: 'create', //新建账户
                name: 'create',
                component: () => import('../views/login_module/register.vue'),
                meta: {
                    isLogin: true
                },
            },
            {
                path: '/resetPassword', //忘记密码
                name: 'resetPassword',
                component: () => import('../views/login_module/register.vue'),
                meta: {
                    isLogin: true
                },
            }
        ]
    },
    /* 工作台*/
    {
        name: 'Workbench',
        path: '/',
        redirect: '/index',
        meta: {
            title: '工作台',
            selected: true,
            defaultIcon: 'home_tab/tab_workbench.png',
            ActiveDefaultIcon: 'home_tab/tab_workbench_active.png'
        },
        component: () => import('../views/layout'),
        children: [{
                path: 'index',
                name: 'workbenchIndex',
                component: () => import('../views/workbench_module'),
            },
            {
                path: 'custom',
                name: 'workbenchCustom',
                meta: {
                    selected: true,
                    title: '招商事项台帐',
                },
                component: () => import('../views/workbench_module/attract_matter'),
            },
            {
                path: 'attractProject',
                name: 'workbenchAttractProject',
                meta: {
                    selected: true,
                    title: '招商项目管理'
                },
                component: () => import('@/views/workbench_module/attract_project')
            }
        ]
    },
    /* 资源库 */
    {
        name: 'ResourceLibrary',
        path: '/resource',
        redirect: '/resource/index',
        meta: {
            title: '资源库',
            selected: true,
            defaultIcon: 'home_tab/tab_resource.png',
            ActiveDefaultIcon: 'home_tab/tab_resource_active.png'
        },
        component: () => import('../views/layout'),
        children: [{
                name: 'resourceIndex',
                path: 'index',
                component: () => import('../views/resource_library_module'),
            },
            {
                path: 'company/:id',
                name: 'resourceCompany',
                meta: {
                    selected: true,
                },
                component: () => import('../views/resource_library_module/company'),
            }
        ]
    },
    /* 后台管理*/
    {
        name: 'Management',
        path: '/management',
        redirect: '/management/index',
        meta: {
            title: '后台管理',
            selected: true,
            defaultIcon: 'home_tab/tab_management.png',
            ActiveDefaultIcon: 'home_tab/tab_management_active.png'
        },
        component: () => import('../views/layout'),
        children: [{
            path: 'index',
            name: 'managementIndex',
            component: Empty
        }]
    }
]



const router = new VueRouter({
    routes
})

// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }


router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('token');
    // if (process.env.NODE_ENV == 'development') {
    //     token = "xxxx";
    // }
    if (!token) {
        // router.replace('/login')
        if(to.meta.isLogin){
            next();
        }else{
            next('/login')
        }
    } else {
        if (!to.meta.selected && to.matched.length > 0 && to.matched[0].meta) {
            const route = to.matched[0];
            route.fullPath = to.fullPath
            store.dispatch('add_active_menus', route);
        } else if (to.meta.selected) {
            store.dispatch('add_active_menus', to);
        }
        next()
    }
});

export default router