import { projectGetList, projectGetListTotal } from "@/api/crm/workbench";
const table = {
    state() {
        return {
            tableTh: [
                {
                    title: "项目名称",
                    type: "projName",
                    className: "active-color line-max-2 clicked",
                    width: 200,
                },
                {
                    title: "归属企业",
                    type: "entName",
                    className: "line-max-2",
                    width: 160,
                },
                {
                    title: '录入时间',
                    type: 'createTime',
                },
                {
                    title: '录入人',
                    type: 'creator'
                },
                {
                    title: "最新状态",
                    type: "statusDesc",
                    className: "",
                },

                {
                    title: "我方负责人",
                    type: "fzr",
                    className: "",
                },
                {
                    title: "客户负责人",
                    type: "cstName",
                    className: "",
                },
                {
                    title: "渠道来源",
                    type: "channelSource",
                    className: "",
                },

            ],
            operate: ['项目登记', '拜访考察', '项目选址', '项目评审', '项目签约'],
            projectList: [],
            pageSize: 1,
            page: 1,
            total: 0,
            dialogVisible: false
        }
    },
    mutations: {
        SET_PROJECT_LIST(state, list) {
            state.projectList = list;
        },
        SET_PAGE(state, data) {
            state.page = data.count;
        },
        SET_TOTAL(state, num) {
            state.total = num;
        },
        RESTE_LIST(state) {
            state.projectList = [];
            state.page = 1;
        },
        SET_DIALOGVISIBLE(state, val) {
            state.dialogVisible = val;
        }
    },
    actions: {
        projectList({ commit, state }, data) {  //获取列表
            projectGetList(`${state.page}/10`, data).then(res => {
                if (res.status == 200) {
                    res.data.forEach((val) => {
                        val.priorityClass = `priority_${val.priority}`;
                    });
                    commit('SET_PROJECT_LIST', res.data);
                }
            })
        },
        projectTotalNum({ commit }, data) {
            projectGetListTotal(data).then((res) => {
                if (res.status == 200) {
                    commit('SET_TOTAL', res.data)
                }
            });
        },
        getList({ dispatch }) {
            dispatch("projectList", {
                itemType: 0,
                keyword: "",
            });
            dispatch("projectTotalNum", {
                itemType: 0,
                keyword: "",
            });
        }
    }
}
export default table;