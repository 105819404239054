<template>
  <div id="empty">
    <img class="empty-icon" src="@/assets/images/empty.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#empty {
  width: 100%;
  height: 100%;
  position: relative;
  .empty-icon {
    width: 160px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>