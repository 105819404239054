const step = {
    state() {
        return {
            active: -1,
            check: 0,  //当前查看
            list: [
                {
                    type: 'register',
                    name: "项目登记",
                    status: 2, //status 0：未开始 2：已完成 
                    condition: 1,  //state 1:review 2:add 3:edit
                },
                {
                    type: 'visit',
                    name: "拜访考察",
                    status: 0, //status 0：未开始 2：已完成
                    condition: 1,
                },
                {
                    type: 'site-select',
                    name: "项目选址",
                    status: 0, //status 0：未开始 2：已完成
                    condition: 1,
                },
                {
                    type: 'review',
                    name: "项目评审",
                    status: 0, //status 0：未开始 2：已完成
                },
                {
                    type: 'sign',
                    name: "项目签约",
                    status: 0, //status 0：未开始 2：已完成
                },
            ],
        }
    },
    mutations: {
        SET_ACTIVE(state, index) {
            state.active = index;
        },
        SET_CHECK(state, index) {
            state.check = index;
        },
        SET_CONDITION(state, data) {
            state.list[state.active].condition = data.val;
        },
        RESTE(state) {
            state.active = -1;
            state.check = 0;
            state.list.forEach(val => {
                val.condition = 1;
            });
        },
        SET_STATUS(state, data) {
            state.list[data.index].status = data.val
        },
        RESET_STATUS(state) {
            state.list.forEach(val => {
                val.status = 0;
            });
        }
    },
    getters: {
        getByType: (state) => (index) => {
            return state.list[index];
        },
        getByCheck: (state) => {
            return state.list[state.check];
        },
        getByActive: (state) => {
            if (state.active == -1) return '';
            return state.list[state.active];
        }
    }

}
export default step;