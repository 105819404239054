import {
    projectGetInfo,
    projectShareHolder,
} from "@/api/crm/workbench";
const baseInfo = {
    state() {
        return {
            detailData: {
                projectInfo: {
                    lstRecDept: 111
                },
                lstEnterprise: null,
            },
        }
    },
    mutations: {
        SET_PROJECT_INFO(state, data) {
            state.detailData.projectInfo = data;
        },
        SET_SHARE_HOLDER(state, data) {
            state.detailData.lstEnterprise = data;
        },
    },
    actions: {
        getProjectInfo({ commit, state, dispatch, getters }) {
            projectGetInfo(getters.getId).then((res) => {
                if (res.status == 200) {
                    const projectInfo = {
                        ...state.detailData.projectInfo,
                        ...res.data,
                    }
                    commit('SET_PROJECT_INFO', projectInfo);
                    dispatch('getShareHolder');
                }
            });
        },
        getShareHolder({ commit, state, getters }) {
            projectShareHolder(getters.getId).then((res) => {
                if (res.status == 200) {
                    const lstEnterprise = [{
                        lstShareHolder: res.data,
                        shortName: state.detailData.projectInfo.shortName, //简称
                        entName: state.detailData.projectInfo.entName, //全称
                        industry: state.detailData.projectInfo.industry, //产业
                        industryDesc: state.detailData.projectInfo.industryDesc, //产业
                        serviceArea: state.detailData.projectInfo.serviceArea, //服务领域
                    }];
                    commit('SET_SHARE_HOLDER', lstEnterprise);
                }
            });
        },
    },
    getters: {
        getId(state, getters, rootState, rootGetter) {
            return rootGetter.getById
        }
    }
}

export default baseInfo