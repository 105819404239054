const menu = {
    state: () => ({
        active_menus: [],
    }),
    mutations: {
        SET_ACTIVE_MENUS(state, value) {
            state.active_menus = value;
        },
    },
    actions: {
        add_active_menus({ commit, state }, val, index) {
            const path = val.redirect ? val.redirect : val.path;
            const isExit = state.active_menus.filter(x => x.path == path);

            if (isExit.length > 0) {
                isExit[0].fullPath = val.fullPath;
                commit('SET_ACTIVE_MENUS', state.active_menus);
                return
            };
            if (index) {
                state.active_menus.splice(index, 0, {
                    title: val.meta.title,
                    path: path,
                    fullPath: val.fullPath
                });
            } else {
                state.active_menus.push({
                    title: val.meta.title,
                    path: path,
                    fullPath: val.fullPath
                });
            }
            commit('SET_ACTIVE_MENUS', state.active_menus);
        },
        remove_active_menus({ commit, state }, val) {
            state.active_menus.forEach((element, index) => {
                if (element.fullPath == val) {
                    state.active_menus.splice(index, 1);
                }
            });
            commit('SET_ACTIVE_MENUS', state.active_menus);
        },
        setTitle({ commit, state }, { value, index }) {
            state.active_menus[index].title = value;
            commit('SET_ACTIVE_MENUS', state.active_menus);
        }
    }
}

export default menu;